import React from 'react';
import Footer from '../components/Footer';
import MobileMenu from "../components/MobileMenu";
import LoadingImage from '../components/LoadingImage';
import LoadingVideo from '../components/LoadingVideo';

function OperationsPage() {
    return (
        <div className='container operations'>
            <MobileMenu />
            <header className='operations-header'>
                <h2 className='operations-header__title'>Smallest footprint in its class</h2>
                <p className='operations-header__desc'>Utilizing Down-Folding Wings as Landing Gear on  Lift + Cruise eVTOL Aircraft is a patent pending (USPTO #63/342,109) feature of PD250.</p>
                <LoadingVideo autoPlay loop src="./../CargoTron_Website-Animation01_small.mp4" style={{ height: '100%' }}></LoadingVideo>
            </header>

            <section className='bi-modal-solution'>
                <h2 className='bi-modal-solution__title'>Bi-modal solution eliminates hidden overheads.</h2>
                <LoadingImage className='bi-modal-solution__pic' src='./../CargoTron_Website_Bi-ModalCharacteristic.avif' alt='bi modal solution' />
            </section>

            <LoadingImage src='./../CargoTron_Website_DimensionalData.avif' alt='bi modal solution 2' style={{ width: '100%' }} />

            {/*<section className='description'>
                <div className='description__container'>
                    <div className='description__list'>
                        <div className='description__list--item'>
                            <div className='num'>
                                <h4 className='value'>600</h4>
                                <p className='label'>km</p>
                            </div>

                            <h3 className='title'>Maximum Range</h3>
                            <h3 className='desc'>thanks to the hybrid power-plant.</h3>
                        </div>

                        <div className='description__list--item'>
                            <div className='num'>
                                <h4 className='value'>250</h4>
                                <p className='label'>kg</p>
                            </div>

                            <h3 className='title'>Payload Capacity</h3>
                            <h3 className='desc'>within 2 m<sup>3</sup> replaceable cargo pod.</h3>
                        </div>

                        <div className='description__list--item'>
                            <div className='num'>
                                <h4 className='value'>140</h4>
                                <p className='label'>m<sup>2</sup></p>
                            </div>

                            <h3 className='title'>Safe Landing Area</h3>
                            <h3 className='desc'>as per CAP437, negating infrastructure needs.</h3>
                        </div>
                    </div>
                </div>
                <img className='description__bg' src='./../description-bg.png' alt='description' />
    </section>*/}

            <Footer />
        </div >
    )
}

export default OperationsPage;