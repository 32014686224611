import React from 'react';

const OpenUrlButton = ({ url, children }) => {
  const handleClick = () => {
    window.open(url, '_blank');
  };

  return (
    <button className='btn' onClick={handleClick}>
      {children}
    </button>
  );
};

export default OpenUrlButton;