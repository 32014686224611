import React from 'react';
import Footer from '../components/Footer';
import MobileMenu from "../components/MobileMenu";

function AboutPage() {
    return (
        <div className='container about'>
            <MobileMenu />
            <header className='about-header'>

                <div className='about-header__container'>
                    <h1 className='about-header__title'>ABOUT</h1>
                    <p className='about-header__desc'>CargoTron was established in 2021 for the sole purpose of bringing the PD250 program to life. The Core Team is composed of members with a total experience exceeding 150 years in; managing, designing, testing, manufacturing, certifying, delivering  and supporting airborne systems. Our program strategy is made up of the following elements:</p>
                </div>
            </header>

            <section className='about-content'>
                <div className='about-content__container'>
                    <div className='about-content__col'>
                        <h4 className='title'>Value the Experience</h4>
                        <p className='desc'>Put together a team that knows how to design and manufacture; certifiable, deliverable and supportable airborne systems.</p>

                        <h4 className='title'>Safety is First</h4>
                        <p className='desc'>The end item(s) shall meet or exceed the existing applicable safety standards.</p>

                        <h4 className='title'>Define Market Segment</h4>
                        <p className='desc'>Rapid, time-definite delivery segment of Less than Truck Load Business to Business market</p>

                        <h4 className='title'>Specify Customer</h4>
                        <p className='desc'>Logistic Service Providers (LSP) and 3rd Party Logistic Service Providers (3LP).</p>

                        <h4 className='title'>Understand the Need</h4>
                        <p className='desc'>Answer a solid existing need, not a hypothetical one.</p>
                    </div>

                    <div className='about-content__col'>
                        <h4 className='title'>Respect Legacy Practices</h4>
                        <p className='desc'>Design a stand-alone system with clean interfaces to the existing processes of the customer.</p>

                        <h4 className='title'>Deliver Best Value</h4>
                        <p className='desc'>Consider the whole and aim for overall best value, instead of excelling in one single characteristic.</p>

                        <h4 className='title'>Do Not Over-innovate</h4>
                        <p className='desc'>Combine proven technologies in a unique way.</p>

                        <h4 className='title'>Maintain Flexibility</h4>
                        <p className='desc'>Avoid rigid solutions that disregards alternative customer demands or technological progress.</p>

                        <h4 className='title'>Be Cost Conscious</h4>
                        <p className='desc'>Devise a business model with cost saving opportunities for an affordable and competitive solution.</p>
                    </div>
                </div>
            </section>

            <Footer />
        </div >
    )
}

export default AboutPage;