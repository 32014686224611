import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <footer className='main-footer'>
            <div className='main-footer__container'>
                <img className='main-footer__logo' src='./../footer-logo.avif' alt='Footer logo' />

                <div className='main-footer__links'>
                    <Link to='/' className='main-footer__links--item'>Home</Link>
                    <Link to='/about' className='main-footer__links--item'>About</Link>
                    <Link to='/usecases' className='main-footer__links--item'>Use Cases</Link>
                    <Link to='/blog' className='main-footer__links--item'>Blog</Link>
                    <Link to='/contact' className='main-footer__links--item'>Contact Us</Link>
                </div>

                <p className='main-footer__copy'>© 2023 by CargoTron</p>
            </div>
        </footer>
    )
}