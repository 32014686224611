import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import OperationsPage from './pages/OperationsPage';
import AboutPage from './pages/AboutPage';
import UseCasesPage from './pages/UseCasesPage';
import BlogPage from './pages/BlogPage';
import ContactUsPage from './pages/Contactus'
import ScrollToTop from './components/ScrollToTop';
import './css/main.css';
import ReactGA from "react-ga4";

function App() {
  ReactGA.initialize("G-WEGSH5P8TR");
  return (
    <div className="main-container">
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='operations' element={<OperationsPage />} />
        <Route path='about' element={<AboutPage />} />
        <Route path='usecases' element={<UseCasesPage />} />
        <Route path='blog' element={<BlogPage />} />
        <Route path='contact' element={<ContactUsPage />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;