import React from 'react';
import "../css/ScrollButton.css";

const ScrollButton = ({ targetId }) => {
  const handleClick = () => {
    const target = document.getElementById(targetId);
    if (target) {
      // target.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className="scroll-button-container">
        <div className="scroll-button">
        ↓
        </div>
    </div>
  );
};

export default ScrollButton;