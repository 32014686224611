import React from "react";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import "../css/Contactus.css";

function ContactUsPage() {
  return (
    <div className="">
      <div className="container contact">
        <MobileMenu />
        <header className="usecases-header">
          <div className="usecases-header__container">
            <h1 className="usecases-header__title">CONTACT US</h1>
            <br />
            <img width="200" height="30" src='./../email.png' alt="em"></img>
          </div>
        </header>

        {/*<section className="usecases-content">
        <div className="usecases-content__container">
            <form method="post">
                <div><input id="name-1" type="text" name="name" placeholder="Name" /></div>
                <div><input id="email-1" type="email" name="email" placeholder="Email" /></div>
                <div><textarea id="message-1" name="message" rows="6" placeholder="Message"></textarea></div>
                <div><button className="contact-btn" type="submit">Send</button></div>
            </form>
        </div>
      </section>*/}
      </div>
      <Footer />
    </div>
  );
}

export default ContactUsPage;
