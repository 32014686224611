import React from "react";
import { slide as Menu } from "react-burger-menu";
import "../css/MobileMenu.css";

const MobileMenu = () => {
  return (
    <Menu right>
      <a className="menu-item" href="/">
        Home
      </a>
      <a className="menu-item" href="/about">
        About
      </a>
      <a className="menu-item" href="/usecases">
        Use Cases
      </a>
      <a className="menu-item" href="/blog">
        Blog
      </a>
      <a className="menu-item" href="/contact">
        Contact Us
      </a>
    </Menu>
  );
};

export default MobileMenu;
