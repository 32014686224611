import React from 'react';
import Footer from '../components/Footer';
import MobileMenu from "../components/MobileMenu";
import LoadingImage from '../components/LoadingImage';

function UseCasesPage() {
    return (
        <div className='container usecases'>
            <MobileMenu />
            <header className='usecases-header'>
                <div className='usecases-header__container'>
                    <h1 className='usecases-header__title'>USE CASES</h1>
                </div>
            </header>

            <section className='usecases-content'>
                <div className='usecases-content__container'>
                    <div className='usecases-content__item'>
                        <LoadingImage className='usecases-content__item--img' src='./../civillian-tasks.avif' alt='Civillian Tasks' />
                        <h4 className='usecases-content__item--title'>Civillian Tasks</h4>
                        <div className='usecases-content__item--details'>
                            <p className='usecases-content__item--desc'>Middle-Mile B2B LTL Express Cargo Delivery</p>
                            <p className='usecases-content__item--desc'>First-Mile/ Last-Mile B2B Courier, Express & Parcel Delivery</p>
                            <p className='usecases-content__item--desc'>Off-Shore Express Cargo Delivery (Oil Rigs, Wind Farms, etc.)</p>
                            <p className='usecases-content__item--desc'>Mid-to-Long Range Humanitarian Emergency Deliveries</p>
                        </div>
                    </div>

                    <div className='usecases-content__item'>
                        <LoadingImage className='usecases-content__item--img' src='./../security-tasks.avif' alt='Security Tasks' />
                        <h4 className='usecases-content__item--title'>Security Tasks</h4>
                        <div className='usecases-content__item--details'>
                            <p className='usecases-content__item--desc'>Coastline Surveillance</p>
                            <p className='usecases-content__item--desc'>Border Surveillance</p>
                            <p className='usecases-content__item--desc'>Law Enforcement</p>
                        </div>
                    </div>

                    <div className='usecases-content__item'>
                        <LoadingImage className='usecases-content__item--img' src='./../military-tasks.avif' alt='Military Tasks' />
                        <h4 className='usecases-content__item--title'>Military Tasks</h4>
                        <div className='usecases-content__item--details'>
                            <p className='usecases-content__item--desc'>Close Air Fire Support (CAS)</p>
                            <p className='usecases-content__item--desc'>MEDEVAC</p>
                            <p className='usecases-content__item--desc'>CASEVAC</p>
                            <p className='usecases-content__item--desc'>Battlefield Replenishment</p>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div >
    )
}

export default UseCasesPage;