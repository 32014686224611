import React, { useState } from 'react';
import { BarLoader } from 'react-spinners';

const LoadingVideo = ({ src, autoPlay, loop, style, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleVideoLoad = () => {
    setIsLoading(false);
  };

  return (
    <div style={{ position: 'relative', ...style }}>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <BarLoader />
          <p style={{
            fontFamily: "Montserrat",
            fontSize: '1.8rem',
            fontWeight: '700'
          }}
           >Loading...</p>
        </div>
      )}
      <video
        autoPlay
        loop
        src={src}
        onLoadedData={handleVideoLoad}
        style={isLoading ? { display: 'none' } : {height: '100%', zIndex: '-10'}}
        {...props}
      />
    </div>
  );
};

export default LoadingVideo;