import React from 'react';
import Footer from '../components/Footer';
import MobileMenu from "../components/MobileMenu";
import OpenUrlButton from '../components/OpenUrlButton';
import LoadingImage from '../components/LoadingImage';

function BlogPage() {
    return (
        <div className='container blog'>
            <MobileMenu />
            <header className='blog-header'>
                <div className='blog-header__container'>
                    <h1 className='blog-header__title'>BLOG</h1>
                </div>
            </header>

            <section className='blog-content'>
                <div className='blog-content__container'>
                    <div className='blog-content__item'>
                        <LoadingImage className='blog-content__item--img' src='./../CargoTron_Website_Blog01.avif' alt='blog' />

                        <div className='blog-content__item--body'>
                            <p className='date'>August 2020</p>
                            <h3 className='title'>Cargo eVTOL: Are We Missing a Logical Step?</h3>
                            <p className='desc'>On-line sales are booming and have become the fastest growing segment in retail sales. However, that growth is constrained...</p>
                            <OpenUrlButton url='https://www.linkedin.com/pulse/cargo-evtol-we-missing-logical-step-mehmet-emre-yazici/'>Read More</OpenUrlButton>
                        </div>
                    </div>

                    <div className='blog-content__item'>
                        <LoadingImage className='blog-content__item--img' src='./../CargoTron_Website_Blog02.avif' alt='blog' />

                        <div className='blog-content__item--body'>
                            <p className='date'>August 2020</p>
                            <h3 className='title'>Where is my Cargo Drone?</h3>
                            <p className='desc'>We are all excited about the upcoming revolution in aviation. Namely: the autonomous electric flight! Because, deep in...</p>
                            <OpenUrlButton url='https://www.linkedin.com/pulse/where-my-cargo-drone-mehmet-emre-yazici/'>Read More</OpenUrlButton>
                        </div>
                    </div>

                    <div className='blog-content__item'>
                        <LoadingImage className='blog-content__item--img' src='./../CargoTron_Website_Blog03.avif' alt='blog' />

                        <div className='blog-content__item--body'>
                            <p className='date'>May 2019</p>
                            <h3 className='title'>Urban Air Mobility - How big is it, really?</h3>
                            <p className='desc'>The initial hype about the size of the future Urban Air Mobility market, that reached its peak with the Morgan Stanley's...</p>
                            <OpenUrlButton url='https://www.linkedin.com/pulse/urban-air-mobility-how-big-really-mehmet-emre-yazici/'>Read More</OpenUrlButton>
                        </div>
                    </div>

                    <div className='blog-content__item'>
                        <LoadingImage className='blog-content__item--img' src='./../CargoTron_Website_Blog04.avif' alt='blog' />

                        <div className='blog-content__item--body'>
                            <p className='date'>January 2019</p>
                            <h3 className='title'>Urban Air Mobility - The Quest for the Right Shape</h3>
                            <p className='desc'>For the first time in known history, humankind is this close to make the “flying-car” a reality. Today, 70+ manufacturers...</p>
                            <OpenUrlButton url='https://www.linkedin.com/pulse/urban-air-mobility-quest-right-shape-mehmet-emre-yazici/'>Read More</OpenUrlButton>
                        </div>
                    </div>

                    <div className='blog-content__item'>
                        <LoadingImage className='blog-content__item--img' src='./../CargoTron_Website_Blog05.avif' alt='blog' />

                        <div className='blog-content__item--body'>
                            <p className='date'>April 2017</p>
                            <h3 className='title'>The Dawn of the Mega-Drone</h3>
                            <p className='desc'>Up to day, approximately 72 percent of all civilian drones purchased are in the $1,000 - $2,000 price range, where only 1% ...</p>
                            <OpenUrlButton url='https://www.linkedin.com/pulse/dawn-mega-drone-mehmet-emre-yazici/'>Read More</OpenUrlButton>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default BlogPage;