import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import MobileMenu from "../components/MobileMenu";
import LoadingImage from '../components/LoadingImage';
import ScrollButton from '../components/ScrollButton';

function HomePage() {
    return (
        <div className='container home'>
            <MobileMenu />
            <header className='header'>
                <LoadingImage className='header__logo' src='./../header-logo.avif' alt='logo' />
                <ScrollButton targetId="about" />
            </header>

            <section id='about' className='about'>
                <div className='about__pic' src='./../The-city-2560x1600.avif' alt='about' />

                <div className='about__container'>
                    <h2 className='about__title'>Traditional 2D transport networks have reached their limits</h2>

                    <div className='about__details'>
                        <p className='about__details--item'>As consumer demand shifts further and further towards e-commerce,  B2B transactions  that must be delivered by less-than-truck-load, or LTL freight shipping,</p>
                        <p className='about__details--item'>have increased at the same time. Because of this, small to medium sized businesses will have an increasing demand for express LTL shipping services to timely deliver their products to their customers.  </p>
                        <p className='about__details--item'>However, this demand is constrained by the congested ground transport, especially in and around urban areas. Now, there is a solution!</p>
                    </div>
                </div>
            </section>

            <section className='features'>
                <div className='features__container'>
                    <h2 className='features__title'>It is time to conquer the 3rd dimension!</h2>

                    <div className='features__list'>
                        <div className='features__list--item'>
                            <div className='icon'>
                                <LoadingImage className='express-icon' src='./../express-icon.png' alt='about' />
                            </div>
                            <p className='label'>Express</p>
                        </div>

                        <div className='features__list--item'>
                            <div className='icon'>
                                <LoadingImage className='ltl-icon' src='./../ltl-icon.png' alt='about' />
                            </div>
                            <p className='label'>LTL</p>
                        </div>

                        <div className='features__list--item'>
                            <div className='icon'>
                                <LoadingImage className='b2b-icon' src='./../b2b-icon.png' alt='about' />
                            </div>
                            <p className='label'>B2B</p>
                        </div>
                    </div>

                    <h3 className='features__title'>PD250 is CargoTron's offer to revolutionize<br /><strong>Express <br /> Less than Truck Load <br /> Business to Business</strong><br />cargo delivery.</h3>
                </div>
                <LoadingImage className='drone__pic' src='./../drone.avif' alt='features' />
                <div className='cloud__pic' alt='about' />
            </section>

            <section className='operations'>
                <div className='operations__container'>
                    <h2 className='operations__title'>Skip Inner-City Hubs!<br />Deliver direct from regional or consolidation hubs.</h2>

                    <div className='operations__details'>
                        <p className='operations__details--item'>According to Drone Industry Insights (DroneII) website, the combination of direct, first-mile/ last-mile, mid-mile/ feeder, and hub forwarding will be a key component for the logistics and success of drone delivery.</p>
                        <p className='operations__details--item'>PD250 is designed to operate not only over the last mile, but also the middle mile. It flies directly from regional or consolidation hubs of Logistic Service Provider's (LSP) or Third Party Logistics Provider's (3PL) to customers' premises.</p>
                        <p className='operations__details--item'>PD250 may serve any business that can spare 13 meter diameter area in their backyards or rooftops  for safe take-off and landing. That is about one-third of the landing area required by competition.</p>
                    </div>
                </div>

                <div className='operations__pic'>
                    <LoadingImage className='pic' src='./../CargoTron_Website_B2BOperation.avif' alt='CargoTron Website B2BOperation' />
                    <Link to='/operations' className='btn'>LEARN MORE</Link>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default HomePage;